<template>
  <div>
    <b-container>
      <b-row align-h="center" v-if="false">
        <router-link :to="{ name: 'home' }">Templates</router-link>
        <router-link :to="{ name: 'home' }">Pricing</router-link>
        <router-link :to="{ name: 'home' }" href="#">Apps</router-link>
        <router-link :to="{ name: 'home' }">Blog</router-link>
        <router-link :to="{ name: 'home' }">About</router-link>
        <router-link :to="{ name: 'home' }">Help</router-link>
      </b-row>

      <b-row align-h="center" class="in-center">
        <p style="font-size: 0.8rem;margin: 0;color: #8c8c8c;">Copyright© 2019-2023 {{ appName }}{{system===0?"企业":""}}年报通 All Rights Reserved.</p>
      </b-row>
      <b-row align-h="center" class="in-center">
        <p style="font-size: 0.8rem;color: #8c8c8c;">{{icp}}</p>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "MyFooter",
  data:function () {
    return {
      system: 1,
      appInfo:[
        {
          appName:"怡信",
          telephone:'18973819131',
          mpUrl:'https://51w.co/chhOl',
          company: "娄底怡信信息咨询有限公司",
          icp:'湘ICP备2023010289号-1'
        },
        {
          appName:"千里",
          telephone:'13142239783',
          mpUrl:'https://z.9i5.cc/0000',
          company: "湖南志在千里科技有限公司",
          icp:'湘ICP备2023025621号-2'

        },
        {
          appName:"凭信",
          telephone:'13751262079',
          mpUrl:'https://w.9i5.cc/0000',
          company: "湖南凭信网络科技有限公司",
          icp:"湘ICP备2023025693号-2"
        }
      ]
    }
  },
  computed: {
    appName() {
      console.log("system", this.system);
      return this.appInfo[this.system].appName
    },
    telephone(){
      return this.appInfo[this.system].telephone
    },
    mpUrl(){
      return this.appInfo[this.system].mpUrl
    },
    company(){
      return this.appInfo[this.system].company
    },
    icp(){
      return this.appInfo[this.system].icp
    },
  },
};
</script>

<style lang="less" scoped>
a {
  width: auto !important;
  color: #7e7e7e;
  display: inline-block;
  text-decoration: underline;
  margin-right: 1rem;
}

p {
  width: auto !important;
}

a:hover,
a:active {
  color: #000000;
}
</style>