<template>
  <div>
    <b-navbar type="light" fixed="top" class="right">
      <b-img
      v-if="system===0"
        src="logo.jpg"
        style="height: 100%; position: absolute"
        fluid
        alt="Fluid image"
      >
      </b-img>
      <b-img
      v-if="system===1"
        src="logo2.png"
        style="height: 100%; position: absolute"
        fluid
        alt="Fluid image"
      >
      </b-img>
      <b-img
      v-if="system===2"
        src="logo3.png"
        style="height: 100%; position: absolute"
        fluid
        alt="Fluid image"
      >
      </b-img>
      <b-navbar-brand to="/home" class="mx-3" v-if="system===0" 
        >{{ appName }}{{system===0?"企业":""}}年报通</b-navbar-brand
      >
      <b-navbar-brand to="/home" class="mx-3" v-else style="padding-left:58px"
        >{{system===0?"企业":appName}}年报通</b-navbar-brand
      >

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <template v-if="!hasLogin && false">
          <!-- <b-nav-item :to="{ name: 'login' }"> 登陆 </b-nav-item> -->
          <b-nav-item @click="loginCard"> 登陆 </b-nav-item>
          <b-button :to="{ name: 'signup' }" variant="primary"> 注册 </b-button>
        </template>

        <!--  已经登录  显示用户名以及跳转到主页按钮 -->
        <template v-else-if="hasLogin || true">
          <b-button variant="primary" @click="hanldeNavClick">
            <b-icon icon="list"></b-icon>
          </b-button>
        </template>
      </b-navbar-nav>

      <b-modal id="login-modal">
        <login />
      </b-modal>
    </b-navbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import login from "../views/UserLogin.vue";
export default {
  components: {
    login,
  },
  name: "NavBar",
  data() {
    return {
      system: 1,
      email: "",
      visible: false,
      active: "",
      appInfo:[
        {
          appName:"怡信",
          telephone:'18973819131',
          mpUrl:'https://51w.co/chhOl',
          company: "娄底怡信信息咨询有限公司",
          icp:'湘ICP备2023010289号-1'
        },
        {
          appName:"千里",
          telephone:'13142239783',
          mpUrl:'https://z.9i5.cc/0000',
          company: "湖南志在千里科技有限公司",
          icp:'湘ICP备2023025621号-2'

        },
        {
          appName:"凭信",
          telephone:'13751262079',
          mpUrl:'https://w.9i5.cc/0000',
          company: "湖南凭信网络科技有限公司",
          icp:"湘ICP备2023025693号-2"
        }
      ]
    };
  },
  computed: {
    ...mapState(["user"]),
    hasLogin() {
      return this.user;
    },
    appName() {
      console.log("system", this.system);
      return this.appInfo[this.system].appName;
    },
    telephone() {
      return this.appInfo[this.system].telephone;
    },
    mpUrl() {
      return this.appInfo[this.system].mpUrl;
    },
  },
  mounted() {},
  methods: {
    loginCard() {
      this.$root.$emit("bv::show::modal", "login-modal");
    },
    hanldeNavClick() {
      this.$emit("hanldeNavClick");
    },
    redirctTo() {},
  },
};
</script>

<style scoped lang="less">
.right {
  background: #fff;
  justify-content: space-between;
}
</style>
