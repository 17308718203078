import Vue from "vue"
import VueRouter from "vue-router"
import HomePage from "../views/HomePage.vue"
import UserLogin from "../views/UserLogin.vue"
import SignUp from '../views/SignUp.vue'
import BoardsHome from '../views/BoardsHome.vue'
import Logout from '../views/LogOut.vue'
import MyBoard from '../views/MyBoard.vue'
import NotFound from '../views/NotFound.vue'
import store from "@/store"


Vue.use(VueRouter);
const routes = [
    { path: '/', redirect: '/home' },
    {
        path: "/home",
        name: "home",
        component: HomePage,
    },
    {
        path: "/login",
        name: "login",
        component: UserLogin
    },
    {
        path: '/SignUp',
        name: 'signup',
        component: SignUp
    },
    {
        path: "/templates",
        name: "templates",
        component: BoardsHome,
    },
    {
        path: '/:username/BoardsHome',
        name: 'userBoardsHome',
        component: BoardsHome,
        beforeEnter(to, from, next) {
            if (!store.state.user || to.params.username !== store.state.user.username) {
                next({ name: 'NotFound' },)

            }
            next()
        },
    },
    {
        path: "/logout",
        name: "logout",
        component: Logout,
        beforeEnter(to, from, next) {
            store.commit("LOGOUT_USER");
            next();
        },
    },
    {
        path: '/board/:id/:title',
        name: 'board',
        component: MyBoard,
        beforeEnter(to, from, next) {
            if (!store.state.user || !store.getters.getBoardById(to.params.id)) {
                next({ name: 'not-found' })
            }
            next()

        },
    },
    {
        path: "*",
        name: "not-found",
        component: NotFound,
    },

]

const router = new VueRouter({
    mode: "hash",
    //   base: process.env.BASE_URL,
    routes,
})

export default router;
